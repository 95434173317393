module.exports = [
  {
    "type": "text",
    "page": 1,
    "top": "142",
    "left": "139",
    "width": "617",
    "height": "33",
    "font": "LucioleBold",
    "size": "25",
    "label": "ATTESTATION DE DÉPLACEMENT DÉROGATOIRE"
  },
  {
    "type": "text",
    "page": 1,
    "top": "169",
    "left": "229",
    "width": "437",
    "height": "24",
    "font": "Luciole",
    "size": "18",
    "label": "Valable en France métropolitaine (hors Outre-mer)"
  },
  {
    "type": "text",
    "page": 1,
    "top": "195",
    "left": "230",
    "width": "451",
    "height": "12",
    "font": "Luciole",
    "size": "9",
    "label": "En application de l'article 4 du décret n°2021-699 du 1er juin 2021 prescrivant les mesures générales",
    "block": 2
  },
  {
    "type": "text",
    "page": 1,
    "top": "207",
    "left": "325",
    "width": "408",
    "height": "12",
    "font": "Luciole",
    "size": "9",
    "label": "nécessaires à la gestion de la sortie de crise sanitaire."
  },
  {
    "type": "input",
    "inputs": ["firstname", "lastname"],
    "page": 1,
    "top": "246",
    "left": "63",
    "width": "100",
    "height": "16",
    "font": "Luciole",
    "size": "12",
    "label": "Nom et prénom :",
    "block": 3
  },
  {
    "type": "input",
    "inputs": ["birthday"],
    "page": 1,
    "top": "261",
    "left": "63",
    "width": "172",
    "height": "16",
    "font": "Luciole",
    "size": "12",
    "label": "Date de naissance :"
  },
  {
    "type": "input",
    "inputs": ["address", "zipcode", "city"],
    "page": 1,
    "top": "275",
    "left": "63",
    "width": "124",
    "height": "16",
    "font": "Luciole",
    "size": "12",
    "label": "Adresse du domicile :"
  },
  {
    "type": "text",
    "page": 1,
    "top": "318",
    "left": "50",
    "width": "377",
    "height": "22",
    "font": "LucioleBold",
    "size": "16",
    "label": "Je me déplace entre 6h et 23h pour l’une des raisons suivantes :",
    "block": 3
  },
  {
    "type": "checkbox",
    "reason": "travail",
    "page": 1,
    "top": "378",
    "left": "68",
    "width": "509",
    "height": "18",
    "font": "LucioleBold",
    "size": "13",
    "label": "Activité professionnelle, enseignement et formation, mission d’intérêt général",
    "group": 1,
    "block": 2.8
  },
  {
    "type": "text",
    "page": 1,
    "top": "397",
    "left": "68",
    "width": "580",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements entre le domicile et le lieu de travail ou d’enseignement ou de formation.",
    "group": 1
  },
  {
    "type": "text",
    "page": 1,
    "top": "417",
    "left": "68",
    "width": "430",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements professionnels ne pouvant être différés, livraisons.",
    "group": 1
  },
  {
    "type": "text",
    "page": 1,
    "top": "436",
    "left": "68",
    "width": "622",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements pour effectuer des achats de fournitures nécessaires à l’activité professionnelle.",
    "group": 1
  },
  {
    "type": "text",
    "page": 1,
    "top": "456",
    "left": "68",
    "width": "606",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements liés à des missions d’intérêt général sur demande de l’autorité administrative.",
    "group": 1
  },
  {
    "type": "checkbox",
    "reason": "sante",
    "page": 1,
    "top": "495",
    "left": "68",
    "width": "191",
    "height": "18",
    "font": "LucioleBold",
    "size": "13",
    "label": "Santé (consultations et soins)",
    "group": 2,
    "block": 2.8
  },
  {
    "type": "text",
    "page": 1,
    "top": "514",
    "left": "68",
    "width": "578",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements pour des consultations, examens, actes de prévention (dont vaccination)",
    "group": 2
  },
  {
    "type": "text",
    "page": 1,
    "top": "534",
    "left": "68",
    "width": "522",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "et soins ne pouvant être assurés à distance ou pour l’achat de produits de santé.",
    "group": 2
  },
  {
    "type": "checkbox",
    "reason": "famille",
    "page": 1,
    "top": "573",
    "left": "68",
    "width": "664",
    "height": "18",
    "font": "LucioleBold",
    "size": "13",
    "label": "Motif familial impérieux, assistance aux personnes vulnérables, garde d’enfants, situation de handicap",
    "group": 3,
    "block": 2.8
  },
  {
    "type": "text",
    "page": 1,
    "top": "592",
    "left": "68",
    "width": "676",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements pour motif familial impérieux, pour l’assistance aux personnes vulnérables ou précaires, ",
    "group": 3
  },
  {
    "type": "text",
    "page": 1,
    "top": "612",
    "left": "68",
    "width": "174",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "ou pour la garde d’enfants.",
    "group": 3
  },
  {
    "type": "text",
    "page": 1,
    "top": "631",
    "left": "68",
    "width": "530",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements de personnes en situation de handicap et de leur accompagnant.",
    "group": 3
  },
  {
    "type": "checkbox",
    "reason": "convocation_demarches",
    "page": 1,
    "top": "670",
    "left": "68",
    "width": "577",
    "height": "18",
    "font": "LucioleBold",
    "size": "13",
    "label": "Convocation judiciaire ou administrative, démarches ne pouvant être menées à distance",
    "group": 4,
    "block": 2.8
  },
  {
    "type": "text",
    "page": 1,
    "top": "690",
    "left": "68",
    "width": "642",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements pour répondre à une convocation judiciaire ou administrative, pour se rendre dans",
    "group": 4
  },
  {
    "type": "text",
    "page": 1,
    "top": "709",
    "left": "68",
    "width": "653",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "un service public ou chez un professionnel du droit, pour un acte ou une démarche qui ne peut être ",
    "group": 4
  },
  {
    "type": "text",
    "page": 1,
    "top": "729",
    "left": "68",
    "width": "115",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "réalisé à distance.",
    "group": 4
  },
  {
    "type": "checkbox",
    "reason": "demenagement",
    "page": 1,
    "top": "768",
    "left": "68",
    "width": "104",
    "height": "18",
    "font": "LucioleBold",
    "size": "13",
    "label": "Déménagement",
    "group": 5,
    "block": 2.8
  },
  {
    "type": "text",
    "page": 1,
    "top": "787",
    "left": "68",
    "width": "528",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements liés à un déménagement résultant d’un changement de domicile.",
    "group": 5
  },
  {
    "type": "text",
    "page": 1,
    "top": "807",
    "left": "68",
    "width": "577",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements indispensables à l’acquisition ou à la location d’une résidence principale,",
    "group": 5
  },
  {
    "type": "text",
    "page": 1,
    "top": "826",
    "left": "68",
    "width": "161",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "ne pouvant être différés.",
    "group": 5
  },
  {
    "type": "checkbox",
    "reason": "achats_culte_culturel",
    "page": 1,
    "top": "865",
    "left": "68",
    "width": "325",
    "height": "18",
    "font": "LucioleBold",
    "size": "13",
    "label": "Achats, établissements culturels ou lieux de culte ",
    "group": 6,
    "block": 2.8
  },
  {
    "type": "text",
    "reason": "achats_culte_culturel",
    "page": 1,
    "top": "865",
    "left": "417",
    "width": "205",
    "height": "18",
    "font": "LucioleBoldItalic",
    "size": "13",
    "label": "(au sein de mon département*)",
    "group": 6,
    "sameline": true
  },
  {
    "type": "text",
    "page": 1,
    "top": "885",
    "left": "68",
    "width": "637",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements pour effectuer des achats de première nécessité, pour les retraits de commandes,",
    "group": 6
  },
  {
    "type": "text",
    "page": 1,
    "top": "904",
    "left": "68",
    "width": "292",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "ou pour bénéficier de prestations de service.",
    "group": 6
  },
  {
    "type": "text",
    "page": 1,
    "top": "924",
    "left": "68",
    "width": "578",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements pour se rendre dans un établissement culturel ouvert ou un lieu de culte.",
    "group": 6
  },
  {
    "type": "text",
    "page": 1,
    "top": "944",
    "left": "68",
    "width": "361",
    "height": "16",
    "font": "LucioleItalic",
    "size": "12",
    "label": "Note : Pour les personnes résidant aux frontières d’un département,",
    "group": 6
  },
  {
    "type": "text",
    "page": 1,
    "top": "959",
    "left": "68",
    "width": "404",
    "height": "16",
    "font": "LucioleItalic",
    "size": "12",
    "label": "une tolérance de 30 kilomètres au-delà du département est acceptée.",
    "group": 6
  },
  {
    "type": "checkbox",
    "reason": "sport",
    "page": 1,
    "top": "998",
    "left": "68",
    "width": "280",
    "height": "18",
    "font": "LucioleBold",
    "size": "13",
    "label": "Activité physique, de plein air, promenade ",
    "group": 7,
    "block": 2.8
  },
  {
    "type": "text",
    "reason": "sport",
    "page": 1,
    "top": "998",
    "left": "369",
    "width": "356",
    "height": "18",
    "font": "LucioleBoldItalic",
    "size": "13",
    "label": "(dans un rayon de 10 kilomètres autour de votre domicile)",
    "group": 7,
    "sameline": true
  },
  {
    "type": "text",
    "page": 1,
    "top": "1017",
    "left": "68",
    "width": "600",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements liés à la promenade, à l’activité physique individuelle, à l’activité de plein air,",
    "group": 7
  },
  {
    "type": "text",
    "page": 1,
    "top": "1037",
    "left": "68",
    "width": "261",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "aux besoins des animaux de compagnie.",
    "group": 7
  },
  {
    "type": "text",
    "page": 1,
    "top": "1056",
    "left": "68",
    "width": "682",
    "height": "17",
    "font": "LucioleItalic",
    "size": "13",
    "label": "Vous ne devez remplir l’attestation pour ce motif que si vous ne pouvez présenter un justificatif de domicile.",
    "group": 7
  },
  {
    "type": "input",
    "inputs": ["datesortie"],
    "page": 1,
    "top": "1160",
    "left": "50",
    "width": "23",
    "height": "16",
    "font": "LucioleBold",
    "size": "12",
    "label": "Fait le",
    "group": 8,
    "block": 6
  },
  {
    "type": "input",
    "inputs": ["heuresortie"],
    "page": 1,
    "top": "1160",
    "left": "174",
    "width": "57",
    "height": "16",
    "font": "LucioleBold",
    "size": "12",
    "label": " à",
    "group": 8,
    "sameline" : true
  }
]
;