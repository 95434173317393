module.exports = [
  {
    "type": "text",
    "page": 1,
    "top": "142",
    "left": "139",
    "width": "617",
    "height": "33",
    "font": "LucioleBold",
    "size": "25",
    "label": "ATTESTATION DE DÉPLACEMENT DÉROGATOIRE"
  },
  {
    "type": "text",
    "page": 1,
    "top": "169",
    "left": "229",
    "width": "437",
    "height": "24",
    "font": "Luciole",
    "size": "18",
    "label": "Valable en France métropolitaine (hors Outre-mer)"
  },
  {
    "type": "text",
    "page": 1,
    "top": "195",
    "left": "230",
    "width": "451",
    "height": "12",
    "font": "Luciole",
    "size": "9",
    "label": "En application de l'article 4 du décret n°2021-699 du 1er juin 2021 prescrivant les mesures générales",
    "block": 2
    },
  {
    "type": "text",
    "page": 1,
    "top": "207",
    "left": "325",
    "width": "408",
    "height": "12",
    "font": "Luciole",
    "size": "9",
    "label": "nécessaires à la gestion de la sortie de crise sanitaire."
  },
  {
    "type": "input",
    "inputs": ["firstname", "lastname"],
    "page": 1,
    "top": "246",
    "left": "63",
    "width": "100",
    "height": "16",
    "font": "Luciole",
    "size": "12",
    "label": "Nom et prénom :",
    "block": 3
  },
  {
    "type": "input",
    "inputs": ["birthday"],
    "page": 1,
    "top": "261",
    "left": "63",
    "width": "172",
    "height": "16",
    "font": "Luciole",
    "size": "12",
    "label": "Date de naissance :"
  },
  {
    "type": "input",
    "inputs": ["address", "zipcode", "city"],
    "page": 1,
    "top": "275",
    "left": "63",
    "width": "124",
    "height": "16",
    "font": "Luciole",
    "size": "12",
    "label": "Adresse du domicile :"
  },
  {
    "type": "text",
    "page": 1,
    "top": "318",
    "left": "50",
    "width": "377",
    "height": "22",
    "font": "LucioleBold",
    "size": "16",
    "label": "Je me déplace entre 23h et 6h pour l’une des raisons suivantes :",
    "block": 3
  },
  {
    "type": "checkbox",
    "reason": "travail",
    "page": 1,
    "top": "378",
    "left": "68",
    "width": "509",
    "height": "18",
    "font": "LucioleBold",
    "size": "13",
    "label": "Activité professionnelle, enseignement et formation, mission d’intérêt général",
    "block": 2.8
  },
  {
    "type": "text",
    "page": 1,
    "top": "397",
    "left": "68",
    "width": "580",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements entre le domicile et le lieu de travail ou d’enseignement ou de formation."
  },
  {
    "type": "text",
    "page": 1,
    "top": "417",
    "left": "68",
    "width": "430",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements professionnels ne pouvant être différés, livraisons."
  },
  {
    "type": "text",
    "page": 1,
    "top": "456",
    "left": "68",
    "width": "606",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements liés à des missions d’intérêt général sur demande de l’autorité administrative."
  },
  {
    "type": "checkbox",
    "reason": "sante",
    "page": 1,
    "top": "495",
    "left": "68",
    "width": "191",
    "height": "18",
    "font": "LucioleBold",
    "size": "13",
    "label": "Santé (consultations et soins)",
    "block": 2.8
  },
  {
    "type": "text",
    "page": 1,
    "top": "514",
    "left": "68",
    "width": "578",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements pour des consultations, examens, actes de prévention (dont vaccination)"
  },
  {
    "type": "text",
    "page": 1,
    "top": "534",
    "left": "68",
    "width": "522",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "et soins ne pouvant être assurés à distance ou pour l’achat de produits de santé."
  },
  {
    "type": "checkbox",
    "reason": "famille",
    "page": 1,
    "top": "573",
    "left": "68",
    "width": "664",
    "height": "18",
    "font": "LucioleBold",
    "size": "13",
    "label": "Motif familial impérieux, assistance aux personnes vulnérables, garde d’enfants, situation de handicap",
    "block": 2.8
  },
  {
    "type": "text",
    "page": 1,
    "top": "592",
    "left": "68",
    "width": "676",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements pour motif familial impérieux, pour l’assistance aux personnes vulnérables ou précaires, "
  },
  {
    "type": "text",
    "page": 1,
    "top": "612",
    "left": "68",
    "width": "174",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "ou pour la garde d’enfants."
  },
  {
    "type": "text",
    "page": 1,
    "top": "631",
    "left": "68",
    "width": "530",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements de personnes en situation de handicap et de leur accompagnant."
  },
  {
    "type": "checkbox",
    "reason": "convocation_demarches",
    "page": 1,
    "top": "670",
    "left": "68",
    "width": "577",
    "height": "18",
    "font": "LucioleBold",
    "size": "13",
    "label": "Convocation judiciaire ou administrative, démarches ne pouvant être menées à distance",
    "block": 2.8
  },
  {
    "type": "text",
    "page": 1,
    "top": "690",
    "left": "68",
    "width": "642",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements pour répondre à une convocation judiciaire ou administrative ou pour se rendre chez"
  },
  {
    "type": "text",
    "page": 1,
    "top": "709",
    "left": "68",
    "width": "653",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "un professionnel du droit pour un acte ou une démarche qui ne peut être réalisé à distance."
  },
  {
    "type": "checkbox",
    "reason": "transit",
    "page": 1,
    "top": "768",
    "left": "68",
    "width": "266",
    "height": "18",
    "font": "LucioleBold",
    "size": "13",
    "label": "Déplacements liés à des transferts ou transits depuis des gares ou aéroports dans le cadre",
    "block": 2.8
  },
  {
    "type": "text",
    "page": 1,
    "top": "768",
    "left": "68",
    "width": "266",
    "height": "18",
    "font": "LucioleBold",
    "size": "13",
    "label": "de déplacements de longue distance"
  },
  {
    "type": "checkbox",
    "reason": "animaux",
    "page": 1,
    "top": "768",
    "left": "68",
    "width": "266",
    "height": "18",
    "font": "LucioleBold",
    "size": "13",
    "label": "Animaux de compagnie",
    "block": 2.8
  },
  {
    "type": "text",
    "page": 1,
    "top": "787",
    "left": "68",
    "width": "624",
    "height": "18",
    "font": "Luciole",
    "size": "13",
    "label": "- Déplacements brefs pour les besoins des animaux de compagnie (dans un rayon de 1 kilomètre autour du domicile)."
  },
  {
    "type": "input",
    "inputs": ["datesortie"],
    "page": 1,
    "top": "1160",
    "left": "50",
    "width": "23",
    "height": "16",
    "font": "LucioleBold",
    "size": "12",
    "label": "Fait le",
    "block": 4
  },
  {
    "type": "input",
    "inputs": ["heuresortie"],
    "page": 1,
    "top": "1160",
    "left": "174",
    "width": "57",
    "height": "16",
    "font": "LucioleBold",
    "size": "12",
    "label": " à",
    "sameline": true
  }
]
;